<template>
  <div>
    <span v-if="this.$store.state.isShowCard == false" :class="['toggle-left', isOpen ? 'toggle-arraw' : '']" @click="simgs()"><font-awesome-icon :icon="isOpen ? 'angle-double-right' : 'angle-double-left'"/></span>
    <pdfPreview :url="url" @PdfLodingPage="loadingpage" ref="pdfPreview"></pdfPreview>
  </div>
</template>

<script>
import pdfPreview from '../components/PdfPreview'
export default {
  name: 'pdf',
  components: {
    pdfPreview
  },
  data() {
    return {
      url: '/static/pdf.pdf',
      isOpen: true
    }
  },
  methods: {
    simgs() {
      this.$refs.pdfPreview.simgs('0')
    },
    loadingpage(i) {
      this.$emit('LoadingPdfPage', i)
    },
    pdfloadingmsg(e) {
      this.$emit('PdfloadingMsg', e)
    },
    thumbnailimage() {
      console.log('���õ���һ������')
      this.$refs.pdfPreview.thumbnail()
    }
  }
}
</script>
