<template>
  <div id="hammerBlock" class="pdfpreview" :class="$parent.isOpen ? 'drawer' : ''" ref="pdfsidebar" v-show="!this.$store.state.isRenderingPDFNow">
    <div class="pdfpreview-wrap">
      <div class="pageitem-group">
        <div class="pageitem" v-for="(item, index) in imgArr" :key="index" :name="index + 1" :class="{ selectPage: slide == index + 1 }">
          <div v-show="currentIRSModelList[index] && currentIRSModelList[index] != 'normal'" class="irshint">
            <svg-icon v-show="currentIRSModelList[index] == 'irsBuzz'" icon-class="buzz-new" class="stroke-icon buzz-icon" />
            <svg-icon v-show="currentIRSModelList[index] == 'irsImmediately'" icon-class="Ques" class="stroke-icon ques-icon" />
            <svg-icon v-show="currentIRSModelList[index] == 'irsText'" icon-class="irs-text" class="stroke-icon" />
            <svg-icon v-show="currentIRSModelList[index] == 'irsGather'" icon-class="collate" class="fill-icon" />
          </div>
          <img v-lazy="item" @click="setCarousel(index)" />
          <!-- <div >{{imgArr.idArr[index].pageid}}</div> -->
          <!-- <div >{{item}}</div> -->
          <div class="pagebadge">{{ index + 1 }}</div>
        </div>
      </div>
      <div class="btn-group">
        <div @click="addNew()" class="delpage-btn"><svg-icon icon-class="new-page" class="stroke-icon" /></div>
        <div @click="copyPage()" class="delpage-btn"><svg-icon icon-class="copy" class="fill-icon" /></div>
        <div @click="deleteSelectPage()" class="delpage-btn"><svg-icon icon-class="Close" class="fill-icon" /></div>
      </div>
    </div>
  </div>
</template>
<script>
import PDFJS from 'pdfjs-dist'
import 'hammerjs'
import { mapGetters } from 'vuex'

const de = require('@/utils/lib1.js')
import enc from '@/utils/enc.js'
const width = window.innerWidth * 0.73
const height = window.innerHeight * 0.81
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    manualRender: {
      type: Boolean,
      default: false
    },
    renderPages: {
      // 前后各预渲染多少页
      type: Number,
      default: 5
    },
    showPageLine: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value1: 0,
      slide: 1,
      imgArr: [],
      imgArr2: [],
      pdfDoc: null,
      pageNum: 1,
      pageRendering: false,
      pageNumPending: null,
      scale: 0.9,
      pages: 0,
      currentPage: 0,
      pageHeight: 0,
      renderList: [], // 渲染的页码数组
      timerIndex: 0,
      height: height,
      boardImg: {},
      startNew: 1,
      allPages: this.$store.state.totalpage,
      whiteBG: require('../assets/img/white.jpg'),
      visible: true,
      showSimulatedReturnData: false,
      fileType: '',
      onlyOnePageImg: [],
      initPen: false, //一開始第一張有圖先把圖片畫出
      currentIRSModelList: []
    }
  },
  methods: {
    updateIRSModelList() {
      let tempArr = []
      let msgdata = this.$store.state.msgBody
      if (this.imgArr.idArr && msgdata) {
        this.imgArr.idArr.forEach(element => {
          let index = msgdata.findIndex(x => x.pageid == element.pageid)
          // console.log(index, '目標位置')
          if (index != -1) {
            tempArr.push(msgdata[index].irsmodel)
          } else {
            tempArr.push('normal')
          }
        })
      }
      this.currentIRSModelList = tempArr
    },
    //send Img需先將圖片轉換成file
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    currentPageId(index) {
      if (this.imgArr.idArr) return this.imgArr.idArr[index].pageid
    },
    addNew() {
      this.$store.state.addNew = true
    },
    copyPage() {
      this.$store.state.addNew = true
      this.$store.state.copyPage = true
      // this.$Message.info('複製目前的選中的頁面')
    },
    deleteSelectPage() {
      console.log(this.slide)
      this.$store.state.deletePage = true
      this.$store.state.deletePageNum = this.slide
      //之後處理都送到Board.vue那支去處理
    },
    async setCarousel(index) {
      // this.$parent.$parent.getStorageSize('session')
      this.$parent.$parent.isOpenBuzzPop = false
      this.$parent.$parent.isWaitingBuzz = false
      this.slide = index + 1
      console.log(this.imgArr.idArr[index].pageid)
      this.$parent.$parent.nowpageRender.nowpageIds = await this.imgArr.idArr[index].pageid
      this.$parent.$parent.reSendNormalState()
    },
    pdf(pageNow) {
      var that = this
      that.imgArr = []
      //var url = 'https://teammodelstorage.blob.core.chinacloudapi.cn/teammodelos/wordfiles/IES%20Kubernetes%20on%20Aliyun.pdf'
      var url = this.$store.state.uploadUrl
      console.log(url, '新的url地址')
      var pages = []
      var heights = []
      var width = 0
      var height = 0
      var currentPage = pageNow
      var scale = 1.5
      // function draw() {
      //   var canvas = document.createElement('canvas')
      //   var ctx = canvas.getContext('2d')
      //   canvas.width = window.innerWidth
      //   canvas.height = window.innerHeight
      //   for (var i = 0; i < pages.length; i++) {
      //     ctx.putImageData(pages[i], 0, heights[i])
      //   }
      //   // document.body.appendChild(canvas);
      // }

      PDFJS.disableWorker = true // due to CORS
      if (!url) {
        that.imgArr[0] = that.whiteBG
        that.allPages = that.$store.state.imgArr.length
        var newArr = []
        var newArr2 = []
        for (var i = 0; i < that.imgArr.length; i++) {
          newArr = newArr.concat(that.imgArr[i])
          newArr2 = newArr.concat(that.imgArr[i])
        }
        that.$store.state.totalpage = that.allPages
        that.$store.state.imgArr = newArr // 缩略图数组
        that.$store.state.imgArrOld = newArr2 // 缩略图数组
        that.init()
        return
      }
      //this.$emit('PdfLoding',1)

      PDFJS.getDocument({
        url: url,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.3.200/cmaps/',
        cMapPacked: true
      }).promise.then(function(pdf) {
        console.log(pdf.numPages, '页数')

        //先把頁數設好,watchIdArr初始化資料用
        that.$parent.$parent.totalpage = that.$store.state.initIsWhiteboard == true ? 1 : that.$store.state.PDFInitRenderInfo.totalRenderPage

        getPage()

        that.imgArr.idArr = []

        function getPage() {
          pdf.getPage(currentPage).then(function(page) {
            if (that.$store.state.initIsWhiteboard == false) {
              that.$store.state.PDFInitRenderInfo.currentRenderPage = currentPage
            }

            var viewport = page.getViewport(scale)
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            var renderContext = { canvasContext: ctx, viewport: viewport }
            canvas.height = viewport.height
            canvas.width = viewport.width

            page.render(renderContext).then(async function() {
              // that.$parent.$parent.beginPdfshowLoading(1,currentPage)
              that.$store.state.isRenderingPDFNow = true
              that.$store.state.currentPage = currentPage
              pages.push(ctx.getImageData(0, 0, canvas.width, canvas.height))
              heights.push(height)
              height += canvas.height

              that.$store.state.imgArr.idArr = JSON.parse(sessionStorage.getItem('idArr'))
              // await canvas.toDataURL('image/jpeg', 0.9)
              let pageID = that.$parent.$parent.genID()
              let imgFile = await that.dataURLtoFile(canvas.toDataURL('image/jpeg', 0.6), `${pageID}.jpg`)

              let currentIndex = currentPage - that.$store.state.PDFInitRenderInfo.fromIndex + 1

              let idTypes = {
                pageid: pageID,
                pagetype: 'Quiz',
                page: that.$store.state.initIsWhiteboard == true ? currentPage : currentIndex
              }

              await that.imgArr.idArr.push(idTypes)

              await that.$store.state.pdfOringinalImgsIdArr.push(idTypes) //存放原圖id

              await that.$parent.$parent.uploadResourceFile(imgFile, 'ObjSrcImage').then(async res => {
                console.log(res, '上传Resource成功后的返回')
                await that.imgArr.push(res.url + '?' + that.classInfo.blob.sas_read)
                if (!that.initPen) {
                  that.$store.state.imgArr.idArr = await that.imgArr.idArr
                  that.$parent.$parent.pen(0)
                  that.initPen = true
                }

                await that.$store.state.pdfOringinalImgs.push(res.url + '?' + that.classInfo.blob.sas_read) //存放原圖連結
              })
              //pdf原圖連結備份在本地
              await sessionStorage.setItem('pdfOringinalImgsIdArr', JSON.stringify(that.$store.state.pdfOringinalImgsIdArr))
              await sessionStorage.setItem('pdfOringinalImgs', JSON.stringify(that.$store.state.pdfOringinalImgs))

              //if (width < canvas.width) width = canvas.width

              let renderCondition = that.$store.state.initIsWhiteboard == true ? currentPage < that.$parent.$parent.totalpage : currentPage <= that.$store.state.PDFInitRenderInfo.endIndex - 1
              if (renderCondition) {
                currentPage++
                that.$store.state.PDFInitRenderInfo.renderCount++
                getPage()
              } else {
                // draw() // 页数加载完成后渲染页面
                that.allPages = that.$parent.$parent.totalpage
                that.startNew = currentPage
                var newArr = []
                var newArr2 = []
                for (var i = 0; i < that.imgArr.length; i++) {
                  console.log(i, '缩略图的页数')
                  console.log(that.classInfo.blob)
                  console.log(that.imgArr[i], '缩略图的内容')
                  if (pdf.numPages == 1) {
                    that.onlyOnePageImg = that.imgArr[0]
                  }
                  newArr = newArr.concat(that.imgArr[i])
                  newArr2 = newArr.concat(that.imgArr[i])
                }
                that.$store.state.totalpage = that.allPages
                that.$store.state.imgArr = newArr // 缩略图数组
                that.$store.state.imgArrOld = newArr2 // 缩略图数组
                let ImgArrs = 0
                if (that.$store.state.isOnlyRefresh == false) {
                  ImgArrs = that.$store.state.imgArr.length
                } else {
                  ImgArrs = parseInt(sessionStorage.getItem('slidelength')) + 1
                }
                let imageData = []
                if (that.$store.state.isOnlyRefresh == false) {
                  sessionStorage.removeItem('msgBody')
                  sessionStorage.removeItem('stageInfo')
                }
                let f = async function() {
                  that.$store.state.imgArr = that.imgArr
                  //第一次儲存
                  that.$store.state.imgArrOld = that.imgArr
                  console.log(imageData, '打印查看内容')
                  console.log(that.onlyOnePageImg)
                  that.init()
                }

                f()
              }
            })
          })
        }
        // }
      })
      // this.$store.state.totalpage = this.$store.state.imgArr.length
    },
    //缩略图
    simgs(status) {
      this.fileType = sessionStorage.getItem('fileType')
      if (status === '0') {
        this.$parent.isOpen = !this.$parent.isOpen
        this.allPages = this.$store.state.totalpage

        if (this.$parent.isOpen === false && this.fileType === 'ppt') {
          this.inspectLitimg()
        }
      }
      this.imgArr = this.$store.state.imgArr
      this.showTextLoading()
    },
    uploadImgs(images) {
      // 获取醍摩豆TOKEN
      let that = this
      that.$api
        .uploadBase64({
          params: images,
          method: 'api/file/uploadBase64'
        })
        .then(res => {
          console.log(res)
        })
        .catch(res => {
          console.log(res)
        })
    },
    init() {
      if (sessionStorage.getItem('boardImg')) {
        this.boardImg = JSON.parse(sessionStorage.getItem('boardImg'))
      }
      this.$store.state.carousel = this.$refs.carousel
      this.imgArr = this.$store.state.imgArr
      if (this.$store.state.isOnlyRefresh == false) {
        sessionStorage.removeItem('msgBody')
        this.$parent.$parent.setClassOpTime()
      }
      // this.$emit('PdfLoding', 0)
      if (!this.initPen) {
        this.$parent.$parent.pen(0)
        this.initPen = true
      }
      this.$store.state.isRenderingPDFNow = false
      // //非重刷生成ID
      this.$store.state.imgArr = this.imgArr
    },
    pdfuploaderror() {
      this.$Message.error(this.$t("board['上传失败！请查看PDF页数是否超过20页 是否符合标准']"))
      // this.$emit('PdfLoding', 0)
      this.$parent.$parent.modalUpload = true
    },
    //loading
    showTextLoading() {
      this.visible = true
      //this.showSimulatedReturnData = false
      setTimeout(() => {
        this.visible = false
        //this.showSimulatedReturnData = true
      }, 9000)
    },
    //处理PDF生成id和typeId
    pdfGetidtpe() {
      this.imgArr.idArr = []
      for (var i = 0; i < this.imgArr.length; i++) {
        let idTypes = {
          pageid: this.$parent.$parent.genID(),
          pagetype: 'Quiz',
          page: i
        }
        this.imgArr.idArr.push(idTypes)
      }
      this.$store.state.imgArr = this.imgArr
    },
    //检查缩略图
    async inspectLitimg() {
      var litimaArr = this.$store.state.imgArr
      var totalPages = this.$store.state.totalpage
      var cycleNum = this.$store.state.AfterParsing
      return new Promise(async (r, j) => {
        for (var i = 0; i < litimaArr.length; i++) {
          console.log(litimaArr[i], i, '循环的内容')
          if (litimaArr[i] == undefined || litimaArr[i] == '') {
            console.log(i, '进入到判断方法内')
            let ss = await this.$parent.$parent.initCanvas(i + 1, 'readyRenderBox', 'preparative')
          }
        }
      })
    }
  },
  created() {
    console.log(this.classInfo, '23749723987498')
    //this.pdf(1)
  },
  mounted() {
    //Vertical Pan Hammer.js example
    //https://codepen.io/jtangelder/pen/bGLmMw

    let hammerBlock = document.getElementById('hammerBlock')
    let hammerRecognizers = new Hammer(hammerBlock) // Initialize hammer

    // let the pan gesture support all directions.
    // this will block the vertical scrolling on a touch-device while on the element
    hammerRecognizers.get('pan').set({ direction: Hammer.DIRECTION_ALL })
    hammerRecognizers.on('panup', function(ev) {
      // console.log(hammerBlock.scrollTop,ev.deltaY)
      hammerBlock.scrollTo({
        top: hammerBlock.scrollTop + Math.abs(ev.deltaY),
        behavior: 'smooth'
      })
    })
    hammerRecognizers.on('pandown', function(ev) {
      // console.log(hammerBlock.scrollTop,ev.deltaY)
      hammerBlock.scrollTo({
        top: hammerBlock.scrollTop - Math.abs(ev.deltaY),
        behavior: 'smooth'
      })
    })

    // if(!JSON.parse(sessionStorage.getItem('PDFArr'))){
    //this.pdf(1)
    // }
    if (sessionStorage.getItem('boardImg')) {
      this.boardImg = JSON.parse(sessionStorage.getItem('boardImg'))
    }
    this.$store.state.carousel = this.$refs.carousel
  },
  computed: {
    ...mapGetters({
      classInfo: 'classInfo/getInfo' // 取得課堂設定
    }),
    watchClickPdfnumToOpenSideBar() {
      return this.$store.state.clickPdfnumToOpenSideBar
    },
    watchUrl() {
      return this.$store.state.uploadUrl
    },
    watchImgArr() {
      return this.$store.state.imgArr
    },
    watchSlide() {
      return this.slide
    },
    watchCarouselSlide() {
      // 新增
      return this.$store.state.carouselSlide
    },
    watchIdArr() {
      return this.$store.state.imgArr.idArr
    },
    watchCard() {
      return this.$store.state.isShowCard
    },
    watchPDFInitRenderInfo() {
      return this.$store.state.PDFInitRenderInfo.totalRenderPage
    }
  },
  watch: {
    watchClickPdfnumToOpenSideBar(value) {
      if (value == true) {
        this.$parent.isOpen = true
      } else {
        this.$parent.isOpen = false
      }
    },
    watchCard(value) {
      if (value == true) {
        this.$parent.isOpen = true
      }
    },
    watchUrl(e) {
      //重刷就不需走Pdf渲染流程
      if (e && this.$store.state.isOnlyRefresh == false) {
        if (this.$store.state.initIsWhiteboard == true) {
          this.pdf(1)
        }
        //調整pdf 上傳先跳出選頁數選單watchPDFInitRenderInfo再動作
      }
    },
    watchPDFInitRenderInfo(value) {
      if (value != 0) {
        console.log(this.$store.state.PDFInitRenderInfo, 'PDFInitRenderInfo')
        this.pdf(this.$store.state.PDFInitRenderInfo.fromIndex)
      }
    },
    watchImgArr(e) {
      console.log(e, '监听到缩略图发生变化')
      if (e) {
        this.imgArr = this.$store.state.imgArr
        this.$store.state.imgArr.idArr = this.imgArr.idArr
        this.allPages = this.$store.state.imgArr.length
        this.updateIRSModelList()
      }
    },
    watchSlide(e) {
      if (e) {
        this.$store.state.oldSlide = this.$store.state.carouselSlide
        this.$store.state.carouselSlide = this.slide
        this.boardImg = JSON.parse(sessionStorage.getItem('boardImg'))
      }
    },
    //监听当前页数
    watchCarouselSlide(e) {
      if (e) {
        this.$store.state.oldSlide = this.$store.state.carouselSlide
        this.slide = this.$store.state.carouselSlide
        sessionStorage.setItem('slide', this.slide)
        this.updateIRSModelList()
      }
    }
  },
  beforeDestroy() {
    if (this.timer !== void 0) {
      clearTimeout(this.timer)
      this.$q.loading.hide()
    }
  }
}
</script>
<style lang="less">
@import '../assets/css/pdfPreview.less';
</style>
<style>
.foot {
  position: fixed;
  top: 10px;
  left: 10px;
}

.q-pa-md {
  position: absolute;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.q-carousel {
  height: 100% !important;
}

.q-carousel__slide {
  background-repeat: no-repeat;
  background-size: contain !important;
  padding: 0 !important;
}

.q-carousel__control {
  position: fixed;
  z-index: 99;
}

.q-carousel__navigation {
  position: fixed !important;
  width: 210px;
  top: 40px;
  left: 0;
  background: #f9fafb;
  padding: 10px !important;
  transition: 0.5s;
  overflow: auto;
}

.drawer .q-carousel__navigation {
  left: -210px;
}

.q-carousel__navigation img {
  display: block !important;
  width: 100% !important;
  height: auto !important;
  margin: 12px 0 !important;
  min-height: 100px;
  max-height: 100px;
  border-color: #ddd !important;
  background: #fff;
}

.q-carousel__navigation img.q-carousel__thumbnail--active {
  border-color: #ff9800 !important;
  max-height: 100px;
}

.q-carousel__navigation-inner.flex {
  display: block !important;
}

.pages {
  position: fixed;
  width: 210px;
  height: 40px;
  line-height: 40px;
  top: 0;
  left: 0;
  background: #dfe4e8;
  color: #757f83;
  font-size: 14px;
  padding: 0 10px;
  transition: 0.5s;
  z-index: 999;
}

.drawer.pdfpreview {
  visibility: hidden;
}

.imgbox {
  padding: 15px;
}
</style>
